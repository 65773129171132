import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAnglesDown, faAnglesUp, faCameraRetro, faDesktop, faMobileScreen, faTableColumns, faTabletScreenButton } from '@fortawesome/free-solid-svg-icons'


function Preview() {
   
 const [data, setData] = useState({});
 const [isLoading, setIsLoading] = useState(true);
 const [error, setError] = useState(null);

 let { key } = useParams();

 document.body.classList.add('preview');

 useEffect(() => {
   const apiUrl = 'https://api.differencemachine.co.uk/preview/'+key;

   // Function to fetch data from the API
   const fetchData = async () => {
     try {
       const response = await fetch(apiUrl);
       if (!response.ok) {
         throw new Error(`HTTP error! status: ${response.status}`);
       }
       const json = await response.json();
       setData(json);
       setIsLoading(false);
     } catch (error) {
       setError(error.message);
       setIsLoading(false);
     }
   };

   fetchData();
 }, [key]);

 return (
    <main className="preview-page" role="main">
      <div className="container container-upgrade-wall">

      <div className="row">
        <div className="upgrade-wall">
          <div className="col col-lg-12">
            <h1>Premium feature coming soon...</h1>
            <button className="ui-option">
                [back]
              </button>
          </div>
        </div>
      </div>

      </div>

      <div className="container">

        <div className="row">
          <div className="col col-lg-9">
            <h1 className="preview-title merge">Merge View</h1>
            <h1 className="preview-title sidebyside">Comparison View</h1>
            <h1 className="preview-title stage">Input Capture: Stage</h1>
            <h1 className="preview-title live">Input Capture: Live</h1>
            <ul className="control-panel control-panel-horizontal">
              <li className="active">
                <button className="ui-option active">
                  <FontAwesomeIcon icon={faDesktop} />
                </button>
              </li>
              <li>
                <button className="ui-option viewport-change">
                  <FontAwesomeIcon icon={faTabletScreenButton} />
                </button>
              </li>
              <li>
                <button className="ui-option viewport-change">
                  <FontAwesomeIcon icon={faMobileScreen} />
                </button>
              </li>
            </ul>
            <ul className="control-panel control-panel-vertical">
              <li className="back-btn">
                <a className="results-link" href={`/results/${data.site_key}`}>
                  <FontAwesomeIcon icon={faAngleLeft} />
                </a>
              </li>
              <li>
                <button data-preview="merge" className="ui-option active">
                  <FontAwesomeIcon icon={faCameraRetro} />
                </button>
              </li>
              <li>
                <button data-preview="sidebyside" className="ui-option">
                  <FontAwesomeIcon icon={faTableColumns} />
                </button>
              </li>
              <li>
                <button data-preview="stage" className="ui-option">
                  <FontAwesomeIcon icon={faAnglesDown} />
                </button>
              </li>
              <li>
                <button data-preview="live" className="ui-option">
                  <FontAwesomeIcon icon={faAnglesUp} />
                </button>
              </li>
            </ul>

            <div className="display-option sidebyside">
              <img alt="Side by side" src={`${data.diff}`} />
            </div>

            <div className="display-option stage">
              <img alt="Stage Capture" src={`${data.imageAPath}`} />
            </div>

            <div className="display-option live">
              <img alt="Live Capture" src={`${data.imageBPath}`} />
            </div>

            <div className="display-option merge">

              <ReactCompareSlider
                portrait="true"
                position="10"
                changePositionOnHover="true"
                itemOne={<ReactCompareSliderImage src={data.imageAPath} srcSet={data.imageAPath} alt="Stage Capture" />}
                itemTwo={<ReactCompareSliderImage src={data.imageBPath} srcSet={data.imageBPath} alt="Live Capture" />}
              />

            </div>
          </div>

          <div className="info-panel">

            <h1>{data.page_title_short}</h1>
            <p>{data.page_description ? data.page_description : 'No description'}</p>
            
            <hr className="control-panel-split" />

            

            <span className={`results-changes-found passed ${data.diff_pass===true?'':'hidden'}`}><strong>PASSED</strong></span>
            <span className={`results-changes-found fail ${data.diff_pass===true?'hidden':''}`}><strong>FAILED ({data.diff_percent}&#x25;)</strong></span>

            <div className="btn-group">
              <span>MD5 Hash:<br/>{data.site_key}</span>
              <hr className="control-panel-split" />
              <span>DIFF count: <strong>{data.diff_count}</strong></span>
              <button type="button" className="btn btn-sm btn-outline-secondary rescan-btn">Rescan</button>
              <button type="button" className="btn btn-sm btn-outline-secondary share-btn">SHARE</button>
            </div>

          </div>
        </div>
      </div>
  </main>
  
 );
}

export default Preview;