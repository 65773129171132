import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {Spinner, ProgressBar} from 'react-bootstrap';
import { PieChart } from 'react-minimal-pie-chart';

function Results() {
   
 const [data, setData] = useState(null);
 const [siteName, setSiteName] = useState(null);
 const [siteKey, setSiteKey] = useState(null);
 const [crawlCount, setCrawlCount] = useState(0);
 const [passCount, setPassCount] = useState(0);
 const [failCount, setFailCount] = useState(0);
 const [isLoading, setIsLoading] = useState(true);
 const [error, setError] = useState(null);

 let { key } = useParams();

 useEffect(() => {
   const apiUrl = 'https://api.differencemachine.co.uk/results/'+key;

   // Function to fetch data from the API
   const fetchData = async () => {
     try {
       const response = await fetch(apiUrl);
       if (!response.ok) {
         throw new Error(`HTTP error! status: ${response.status}`);
       }
       const json = await response.json();
       setIsLoading(false);

       return json;
     } catch (error) {
       setError(error.message);
       setIsLoading(false);
       return {};
     }
   };

   const intervalId = setInterval(async () => {
      const json = await fetchData();

      let pass_count = 0;
      let fail_count = 0;

      for (var i in json.results) {
        if (json.results[i].diff_pass===true) {
          pass_count++;
        }else{
          fail_count++;
        }
      }

      setPassCount(pass_count);
      setFailCount(fail_count);

      setSiteName(json.info.production_domain);
      setSiteKey(json.site_key);
      setCrawlCount(json.info.count);
      setData(json.results);

    }, 3000);

    return () => clearInterval(intervalId);

 }, [siteKey, crawlCount]);

 let crawlCountHolder = crawlCount;
 if (crawlCount == 0) crawlCountHolder = 6;

 let resultsToDisplay = crawlCountHolder;
 let paddingNeeded = resultsToDisplay;

 try {
  paddingNeeded = parseInt(resultsToDisplay-Object.keys(data).length);
 }catch(e) {

 }

 return (
  <main role="main" className={`results ${siteKey ? 'loaded' : 'loading'} ${((passCount+failCount)<crawlCount) ? 'crawl-pending' : 'crawl-complete'}`}>
    <div class="album py-5">
      <div class="container">
        <a className="back-btn" href="/">[back]</a>
        <h1 class="results">
          {/* <Spinner animation="grow" size="sm" variant="secondary" /> */}
          {siteName} <small class="meta"> {siteKey ? '('+siteKey+')' : ''}</small>
        </h1>

        <div className="loading-panel">
          <ProgressBar>
            <ProgressBar striped animated variant="success" now={(passCount)/crawlCount*100} key={1} />
            <ProgressBar striped animated variant="danger" now={(failCount)/crawlCount*100} key={2} />
            <ProgressBar striped animated variant="secondary" now={100-((passCount+failCount)/crawlCount*100)} key={3} />
          </ProgressBar>
          <strong class="meta small">
            <span className="time-warning">This may take a few minutes...</span>
            <span>{passCount+failCount} of {crawlCount} completed</span>
          </strong>
        </div>
        <div className="results-panel">
          <div className="row">
            <div class="col-md-1">
              <PieChart
                lineWidth="40"
                segmentsShift="0"
                animate="true"
                data={[
                  { title: 'Pass', value: (passCount)/crawlCount*100, color: '#008000' },
                  { title: 'Fail', value: (failCount)/crawlCount*100, color: '#d9534f' }
                ]}
              />            
            </div>
            <div class="col-md-11">
              <div className="results-data">
                <span class="title-fail">Failed: {failCount}</span> ({failCount/crawlCount*100}%)<br/>
                <span class="title-pass">Passed: {passCount}</span>  ({passCount/crawlCount*100}%)
              </div>
            </div>
          </div>
        </div>

        <div class="row">
        {data && Object.keys(data).map((key, index) => (
          
            <div class="col-md-4">
              <div class="card mb-4 box-shadow">
                <a href={`/preview/${key}`}>
                  <img alt="Preview" class="card-img-top" src={`${data[key].domain_preview}`} />
                </a>
                <div class="card-body">
                  <p class="card-text">
                    <a class="results-link-site" href={data[key].page_compare} rel="noreferrer" target="_blank">{data[key].page_title_short}</a>
                  </p>
                  <p class="card-text card-text-description">
                    {data[key].page_description ? data[key].page_description : 'No description'}
                  </p>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="btn-group">
                      <a class="view-changes" href={`/preview/${key}`}><i class="fa-solid fa-chart-mixed"></i>Inspect</a>
                    </div>
                    <div className={data[key].diff_pass ? '' : 'hidden'}>
                        <span class="results-changes-found passed"><strong>PASSED</strong></span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                          <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                        </svg>
                    </div>
                    <div className={data[key].diff_pass ? 'hidden' : ''}>
                        <a href={data[key].diff}  data-toggle="lightbox" class="results-changes-found fail"><strong>FAILED ({data[key].diff_percent}&#x25;)</strong></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        
        ))}

      {/* Pad out the rest of the display if necessary */}
      {Array.from({ length: paddingNeeded }, (_, index) => (
          <div class={`col-md-4 blank-row ${index===0 ? 'first-blank-row' : ''}`}>
            <Spinner animation="border" size="sm" className="inline-loader" variant="secondary" />
            <img className="card-placeholder" src="/loading.svg" />
          </div>
      ))}

      </div>
    </div>
  </div>

  </main>
  
 );
}

export default Results;