import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faDesktop } from '@fortawesome/free-solid-svg-icons';

function Home() {

  const [domainOriginal, setDomainOriginal] = useState('');
  const [domainComapare, setDomainCompare] = useState('');
  const [data, setData] = useState('');

  const apiUrl = 'https://api.differencemachine.co.uk/';

  useEffect(() => {
    fetch(apiUrl)
      .then(response => response.json())
      .then(data => setData(data));
  }, []);

  const handleDomainOriginalChange = (event) => {
    setDomainOriginal(event.target.value);
  };

  const handleDomainCompareChange = (event) => {
    setDomainCompare(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    const formData = {
      domain_original: domainOriginal,
      domain_compare: domainComapare
    };

    try {

      const response = await fetch(apiUrl+'crawl/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('The API could not be reached.');
      }

      const data = await response.json(); // Assuming the server responds with JSON
      if (data.result === "success") {
        let key = data.key;
        window.location = "/results/"+key;
      }else{
        alert(data.message);
      }
    } catch (error) {
      console.error('Failed to submit form:', error); // Handle error
    }
  };
 
    return (
      <main role="main" class="home">

        <section class="text-center">
          <div>
            <img class="main-image" src="./logo-beta-2-min.png" />
          </div>
          <div class="container">
            <p class="lead text-muted">
              A miraculous contraption ensuring your absolute perfection! <br/>Never fear updates again, for with this extraordinary apparatus, you'll spot the slightest deviation in the wink of an eye!
            </p>

            <div class="form-container">

              <form className="flex-form" onSubmit={handleSubmit}>
                <input className="stage-url" type="search" placeholder="Enter stage URL..." value={domainComapare} onChange={handleDomainCompareChange} />
                <input className="live-url" type="search" placeholder="Enter live URL..."  value={domainOriginal} onChange={handleDomainOriginalChange} />
                <input type="submit" value="submit" />
              </form>
              <div class="form-info">
                <input type="checkbox" id="share-consent" name="share-consent" />
                <label className="consent-label" for="myCheckbox">I agree to share the results (optional)</label>
                <span id="error-messages"></span>
              </div>

            </div>

            <div class="load-more">
              <a href="#crawl-wall">
                <FontAwesomeIcon icon={faChevronDown} />
              </a>
            </div>

          </div>

          <div class="spacer"></div>

          <div className="container">

            <div id="crawl-wall">

              <div class="row">

                {data && Object.keys(data).map((key, index) => (
                  
                    <div class="col-md-3">
                      <div class="card mb-4 box-shadow">
                        <a href={`/preview/${key}`}>
                          <img alt="Preview" class="card-img-top" src={`${data[key].domain_preview}`} />
                        </a>
                        <div class="card-body">
                          <p class="card-text">
                            <a class="results-link-site" href={data[key].page_compare} rel="noreferrer" target="_blank">{data[key].page_title_short}</a>
                          </p>
                          <p class="card-text card-text-description">
                            {data[key].page_description ? data[key].page_description : 'No description'}
                          </p>
                          <div class="d-flex justify-content-between align-items-center">
                            <div class="btn-group">
                              <a class="view-changes" href={`/preview/${key}`}><i class="fa-solid fa-chart-mixed"></i>Inspect</a>
                            </div>
                            <div className={data[key].diff_pass ? '' : 'hidden'}>
                                <span class="results-changes-found passed"><strong>PASSED</strong></span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                  <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
                                </svg>
                            </div>
                            <div className={data[key].diff_pass ? 'hidden' : ''}>
                                <a href={data[key].diff}  data-toggle="lightbox" class="results-changes-found fail"><strong>FAILED ({data[key].diff_percent}&#x25;)</strong></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                
                ))}

              </div>
            </div>

          </div>

        </section>

      </main>
  );

}

export default Home;